
import { computed, defineComponent, inject, onMounted, ref } from "vue";
import { register } from "vue-advanced-chat";
import { useStore } from "vuex";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { Socket } from "socket.io-client";
import { AxiosRequestConfig } from "axios";
import { onBeforeRouteLeave } from "vue-router";

register();

export default defineComponent({
  name: "chat-teacher",
  components: {},
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const socket: Socket = inject("socket") as Socket;

    const sy = window.localStorage.getItem("activeSchoolarYear");
    const currentUserId = ref(window.localStorage.getItem("parentId"));
    const parentsData = ref<Array<any>>([]);
    const selectedTeacher = ref<any>(undefined);
    const selectedStudent = ref<any>(
      store.getters.currentUser.students[0]?._id
    );
    const selectedRoom = ref<any>(null);
    const selectedRoomId = ref<any>(null);
    const messagesLoaded = ref(false);
    const roomsLoaded = ref(false);

    const messageActions = ref([
      {
        name: "deleteMessage",
        title: "Delete Message",
        onlyMe: true,
      },
    ]);
    const textMessages = ref({
      ROOMS_EMPTY: t("chat.ROOMS_EMPTY"),
      ROOM_EMPTY: t("chat.ROOM_EMPTY"),
      NEW_MESSAGES: t("chat.NEW_MESSAGES"),
      MESSAGE_DELETED: t("chat.MESSAGE_DELETED"),
      MESSAGES_EMPTY: t("chat.MESSAGES_EMPTY"),
      CONVERSATION_STARTED: t("chat.CONVERSATION_STARTED"),
      TYPE_MESSAGE: t("chat.TYPE_MESSAGE"),
      SEARCH: t("chat.SEARCH"),
      IS_ONLINE: t("chat.IS_ONLINE"),
      LAST_SEEN: t("chat.LAST_SEEN"),
    });

    const rooms = ref<any[]>([]);
    const messages = ref<any[]>([]);
    const roomActions = ref([]);

    const adminRoom = {
      roomId: "admin",
      roomName: store.getters.serverConfigUrl.building.name + " - الإدارة",
      unreadCount: 0,
      avatar:
        store.getters.serverConfigUrl.building.logo ||
        "media/avatars/blank.png",
      users: [
        {
          _id: currentUserId.value,
          username: "Parent",
          status: {
            state: "online",
            lastChanged: "today, 14:30",
          },
        },
        {
          _id: "admin",
          username: store.getters.serverConfigUrl.building.name + "الإدارة",
          status: { state: "offline" },
        },
      ],
    };

    const visibleAddTeacher = ref(false);
    const loading = ref(true);

    const base_url = store.getters.serverConfigUrl?.base_url;

    const reloadRooms = async (_rooms) => {
      rooms.value = [];
      _rooms = _rooms.filter((room) => room.roomId != "admin");
      const currentAdminRoom = _rooms.find((room) => room.roomId == "admin");

      if (currentAdminRoom) rooms.value = [currentAdminRoom, ..._rooms];
      else rooms.value = [adminRoom, ..._rooms];
    };

    const fetchAndUpdateRooms = async () => {
      let unseenMessages: any[] = [];

      await ApiService.get("/pt-chat/parent/unseenMessages").then((res) => {
        unseenMessages = res.data;
      });

      await ApiService.get(`/chat/parent/unseenMessages`).then(({ data }) => {
        adminRoom.unreadCount = data.count || 0;
      });

      const _rooms: any[] = [];
      let update = false;

      // get all parent chats
      await ApiService.get("/pt-chat/parent/teacherChats")
        .then((res) => {
          res.data.map((teacher: any) => {
            const name = `${teacher.firstName} ${teacher.lastName} `;
            const photo = teacher.photo
              ? base_url + "/" + teacher.photo.replaceAll("\\", "/")
              : "-";
            const status = teacher.online
              ? { state: "online" }
              : { state: "false" };

            const lastMessage = {
              _id: teacher.lastMessage._id,
              content: teacher.lastMessage.message,
              senderId:
                teacher.lastMessage.sentBy == "teacher"
                  ? teacher.lastMessage.parentId
                  : teacher.lastMessage.teacherId,
              username: name,
              saved: true,
              timestamp: new Date(teacher.lastMessage.createdAt)
                .toString()
                .substring(16, 21),
              seen: teacher.lastMessage.seen,
            };
            if (!rooms.value.find((room) => room.roomId == teacher._id)) {
              update = true;
              _rooms.push({
                roomId: teacher._id,
                roomName: name,
                unreadCount:
                  unseenMessages.find((p) => p._id == teacher._id)?.count || 0,
                avatar: photo,
                lastMessage,
                users: [
                  {
                    _id: currentUserId.value,
                    username: "Parent",
                    status: {
                      state: "online",
                      lastChanged: "today, 14:30",
                    },
                  },
                  {
                    _id: teacher._id,
                    username: name,
                    status: status,
                  },
                ],
              });
            }
          });

          reloadRooms([..._rooms, ...rooms.value]);
          if (rooms.value.length > 0 && !update) {
            selectedRoom.value = rooms.value[0];

            if (selectedRoom.value.roomId != "admin") {
              socket.emit("pt-parentSeen", {
                teacherId: selectedRoom.value.roomId,
              });
            } else {
              socket.emit("parentSeen");
            }
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          roomsLoaded.value = true;
        });
    };
    const teachers = {};

    onMounted(async () => {
      await fetchAndUpdateRooms();

      setCurrentPageBreadcrumbs(t("chat.chatWithTeachers"), []);

      roomsLoaded.value = true;

      const classrooms = store.getters.currentUser.students.map(
        (s) => s.schoolarYearsHistory[store.getters.currentYear._id]
      );

      ApiService.post("/schedules/filter", {
        query: {
          classeRoom: { $in: classrooms },
          status: { $ne: 'inactive' },
        },
        aggregation: [
          {
            $lookup: {
              from: "teachers",
              localField: "teacher",
              foreignField: "_id",
              as: "teacher",
            },
          },
          {
            $unwind: "$teacher",
          },
          {
            $lookup: {
              from: "employees",
              localField: "teacher.employee",
              foreignField: "_id",
              as: "employee",
            },
          },
          {
            $unwind: "$employee",
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classeRoom",
              foreignField: "_id",
              as: "classeRoom",
            },
          },
          {
            $project: {
              _id: 1,
              classeRoom: {
                $let: {
                  vars: {
                    classVar: {
                      $arrayElemAt: ["$classeRoom", 0],
                    },
                  },
                  in: {
                    name: "$$classVar.name",
                    _id: "$$classVar._id",
                  },
                },
              },
              teacher: {
                _id: "$teacher._id",
                photo: "$employee.photo",
                firstName: "$employee.firstName",
                lastName: "$employee.lastName",
              },
            },
          },
        ],
      }).then(({ data }) => {
        for (const student of store.getters.currentUser.students) {
          teachers[student._id] = [];
          data.forEach((d) => {
            if (
              student.schoolarYearsHistory[store.getters.currentYear._id] ==
              d.classeRoom._id
            ) {
              const t = teachers[student._id].find(
                (t) => t._id == d.teacher._id
              );
              if (!t) {
                teachers[student._id].push(d.teacher);
              }
            }
          });
        }
      });
    });

    const cancelAddTeacher = () => {
      visibleAddTeacher.value = false;
      selectedTeacher.value = undefined;
    };

    const addNewTeacher = () => {
      visibleAddTeacher.value = true;
    };

    const handleAddTeacher = () => {
      if (!selectedTeacher.value || !selectedStudent.value) return;

      const record = teachers[selectedStudent.value].find(
        (t) => t._id == selectedTeacher.value
      );

      const name = `${record.firstName} ${record.lastName} `;
      const photo = record.photo
        ? base_url + "/" + record.photo.replaceAll("\\", "/")
        : "-";
      const room = {
        roomId: selectedTeacher.value,
        roomName: name,
        unreadCount: 0,
        avatar: photo,
        users: [
          {
            _id: currentUserId.value,
            username: "Parent",
            status: {
              state: "online",
              lastChanged: "today, 14:30",
            },
          },
          {
            _id: record._id,
            username: name,
            status: {
              state: "offline",
            },
          },
        ],
      };

      selectedRoom.value = room;
      selectedRoomId.value = selectedTeacher.value;

      reloadRooms([room, ...rooms.value]);

      socket.emit("request-teacher-status", {
        teacherId: record._id,
      });

      cancelAddTeacher();
    };

    const fetchMessages = ({ room, options = {} }) => {
      selectedRoom.value = room;
      const _messages: any = [];

      messages.value = [];

      messagesLoaded.value = false;

      room.unreadCount = 0;
      rooms.value.map((m) => {
        if (m.roomId == room.roomId) {
          m.unreadCount = 0;
        }
        return m;
      });

      let messagesURL = "/pt-chat/parent/messages/" + room.roomId;
      if (room.roomId == "admin") messagesURL = "/chat/parent";

      // get messages from db of selected room
      ApiService.get(messagesURL)
        .then((res) => {
          rooms.value.map((m) => {
            if (m.roomId == room.roomId) {
              m.users[1].status.state = res.data.online ? "online" : "offline";
            }

            if (
              m.roomId == "admin" &&
              room.roomId == "admin" &&
              res.data.messages.length > 0
            ) {
              const lastMessage =
                res.data.messages[res.data.messages.length - 1];

              m.lastMessage = {
                _id: lastMessage._id,
                content: lastMessage.message,
                senderId:
                  lastMessage.sentBy == "parent"
                    ? lastMessage.parentId
                    : "admin",
                username: m.users[1].username,
                saved: true,
                timestamp: new Date(lastMessage.createdAt)
                  .toString()
                  .substring(16, 21),
                seen: lastMessage.seen,
              };
            }
            return m;
          });

          res.data.messages.map((msg) => {
            const message_files: any = [];
            if (msg.files && msg.files.length > 0) {
              msg.files.map((file) => {
                const splited = file.split(".");
                const splitedName = splited[splited.length - 2].split("--")[1];
                message_files.push({
                  name: splitedName,
                  type: splited[splited.length - 1],
                  url: base_url + "/" + file.replaceAll("\\", "/"),
                  preview: base_url + "/" + file.replaceAll("\\", "/"),
                });
              });
            }

            _messages.push({
              _id: msg.createdAt,
              messageId: msg._id,
              content: msg.message,
              senderId:
                msg.sentBy == "parent" ? currentUserId.value : room.roomId,
              timestamp: new Date(msg.createdAt).toString().substring(16, 21),
              date: moment(msg.createdAt).format("DD/MM/YYYY"),
              files: message_files,
              seen: msg.seen,
              saved: true,
              deleted: Boolean(msg.deleted),
            });
          });

          setTimeout(() => {
            messagesLoaded.value = true;
            messages.value = _messages;

            if (room.roomId != "admin") {
              socket.emit("pt-parentSeen", {
                teacherId: room.roomId,
              });
            } else {
              socket.emit("parentSeen");
            }
          }, 100);
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const sendMessage = (message) => {
      const temp_id = Math.floor(new Date().getTime());
      setTimeout(() => {
        const _messages = [...messages.value];
        _messages.push({
          _id: temp_id, // temp id
          messageId: temp_id, // temp id
          content: message.content,
          senderId: currentUserId.value,
          timestamp: new Date().toString().substring(16, 21),
          date: moment().format("DD/MM/YYYY"),
          files: message.files,
        });
        messages.value = _messages;

        // if message has files upload them first
        if (message.files && message.files.length > 0) {
          const data = new FormData();

          for (const file of message.files) {
            data.append("files", file.blob, file.name + "." + file.extension);
          }

          ApiService.put("/pt-chat/upload", data as AxiosRequestConfig, {
            headers: {
              "Content-Type": `multipart/form-data;`,
            },
          })
            .then((res) => {
              if (selectedRoom.value.roomId != "admin") {
                socket.emit("pt-parentMessage", {
                  message: message.content,
                  teacherId: message.roomId,
                  files: res.data,
                  temp_id,
                });
              } else {
                socket.emit("parentMessage", {
                  message: message.content,
                  files: res.data,
                  temp_id,
                });
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          if (selectedRoom.value.roomId != "admin") {
            socket.emit("pt-parentMessage", {
              message: message.content,
              teacherId: message.roomId,
              files: [],
              temp_id,
            });
          } else {
            socket.emit("parentMessage", {
              message: message.content,
              files: [],
              temp_id,
            });
          }
        }
      }, 50);
    };

    const roomNewMessages = (roomId, count) => {
      const _rooms = [...rooms.value];
      _rooms.map((room) => {
        if (room.roomId == roomId) {
          room.unreadCount += count;
        }
        return room;
      });
      reloadRooms(_rooms);
    };

    const openFile = ({ file }) => {
      if (file.action == "download") window.open(file.file.url, "_blank");
    };

    const deleteMessageHandler = ({ roomId, message }) => {
      socket.emit("pt-deleteMessage", {
        teacherId: roomId,
        messageId: message.messageId,
      });
    };

    const onMessage = async ({
      sentBy,
      teacherId,
      message,
      messageId,
      date,
      files,
      temp_id,
    }) => {
      const messageRoomId = teacherId || "admin";
      const room = rooms.value.find((room) => room.roomId == messageRoomId);
      if (!room) await fetchAndUpdateRooms(); // create new room for parent

      const _messages = [...messages.value];
      const message_files: any[] = [];

      if (files && files.length > 0) {
        files.map((file) => {
          const splited = file.split(".");
          const splitedName = splited[splited.length - 2].split("--")[1];
          message_files.push({
            name: splitedName,
            type: splited[splited.length - 1],
            url: base_url + "/" + file.replaceAll("\\", "/"),
            preview: base_url + "/" + file.replaceAll("\\", "/"),
          });
        });
      }

      if (sentBy == "parent") {
        const msg = _messages.find((message) => message.messageId == temp_id);
        if (!msg) {
          // message sent by defferent socket
          _messages.push({
            _id: date,
            messageId: messageId,
            content: message,
            saved: true,
            seen: false,
            senderId: currentUserId.value,
            timestamp: new Date(date).toString().substring(16, 21),
            date: moment(date).format("DD/MM/YYYY"),
            files: message_files,
          });
        } else {
          // message saved in db
          msg.messageId = messageId;
          msg.saved = true;
          msg.seen = false;
          msg.files = message_files;
          msg.content = message;
        }
      } else {
        if (selectedRoom.value.roomId != messageRoomId) {
          roomNewMessages(messageRoomId, 1);
        } else {
          _messages.push({
            _id: date,
            messageId,
            content: message,
            saved: true,
            seen: false,
            senderId: messageRoomId,
            timestamp: new Date(date).toString().substring(16, 21),
            date: moment(date).format("DD/MM/YYYY"),
            files: message_files,
          });

          if (messageRoomId == "admin") {
            socket.emit("parentSeen");
          } else {
            socket.emit("pt-parentSeen", {
              messageRoomId,
            });
          }
        }
      }

      rooms.value.map((m) => {
        if (m.roomId == messageRoomId) {
          m.lastMessage = {
            _id: messageId,
            content: message,
            senderId: sentBy == "parent" ? messageRoomId : currentUserId.value,
            timestamp: new Date(date).toString().substring(16, 21),
            new: true,
            saved: true,
            seen: false,
          };
        }
      });

      messages.value = _messages;
    };

    const onDelete = ({ messageId, teacherId }) => {
      const messageRoomId = teacherId || "admin";
      if (selectedRoom.value.roomId == messageRoomId) {
        const _messages = [...messages.value];
        const message = _messages.find(
          (message) => message.messageId == messageId
        );
        if (message) {
          message.deleted = true;
          messages.value = _messages;
        }
      }
    };

    const onSeen = ({ teacherId }) => {
      const messageRoomId = teacherId || "admin";
      const _messages = [...messages.value];
      if (selectedRoom.value.roomId == messageRoomId)
        _messages.map((message) => {
          message.seen = true;
          return message;
        });
      messages.value = _messages;

      rooms.value.map((room) => {
        if (room.roomId == messageRoomId && room.lastMessage)
          room.lastMessage.seen = true;
      });
    };

    const onChatStatus = ({ type, teacherId }) => {
      if (type == "teacherOffline")
        rooms.value.map((room) => {
          if (room.roomId == teacherId) room.users[1].status.state = "offline";
          return room;
        });
      else if (type == "teacherOnline")
        rooms.value.map((room) => {
          if (room.roomId == teacherId) room.users[1].status.state = "online";
          return room;
        });
      else console.log("chat-status", type);
    };

    const onAdminStatus = ({ status }) => {
      rooms.value.map((room) => {
        if (room.roomId == "admin") room.users[1].status.state = status;
        return room;
      });
    };

    // listen to new messages from teacher or admin (saved in db)
    socket.on("pt-parentMessage", onMessage);
    socket.on("parentMessage", onMessage);

    // listen to new deleted message from teacher or admin
    socket.on("pt-deletedMessage", onDelete);
    socket.on("deletedMessage", onDelete);

    // listen to new seen from teacher or admin
    socket.on("pt-teacherSeen", onSeen);
    socket.on("adminSeen", onSeen);

    // listening for online/offline status
    socket.on("chat-status", onChatStatus);

    // new status for admin
    socket.on("admin-status", onAdminStatus);

    onBeforeRouteLeave(() => {
      socket.off("pt-parentMessage", onMessage);
      socket.off("parentMessage", onMessage);
      socket.off("pt-deletedMessage", onDelete);
      socket.off("deletedMessage", onDelete);
      socket.off("pt-teacherSeen", onSeen);
      socket.off("adminSeen", onSeen);
      socket.off("chat-status", onChatStatus);
      socket.off("admin-status", onAdminStatus);
      return true;
    });

    return {
      currentUserId,
      rooms,
      messages,
      roomActions,
      visibleAddTeacher,
      t,
      parentsData,
      selectedTeacher,
      handleAddTeacher,
      cancelAddTeacher,
      addNewTeacher,
      fetchMessages,
      messagesLoaded,
      roomsLoaded,
      sendMessage,
      textMessages,
      messageActions,
      openFile,
      deleteMessageHandler,
      students: store.getters.currentUser.students,
      selectedStudent,
      teachers,
      selectedRoomId,
    };
  },
});
